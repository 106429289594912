@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=PT+Mono&display=swap');

html {
    height: 100vh;
}

body {
    background-color: #0B2434;
    color: #2B283A;
    margin: 0;
}

body, button {
    font-family: 'Karla', sans-serif;
}

main {
    max-width: 80%;
    width: 464px;
    margin: 80px auto;
}

.game-container {
    background-color: #F5F5F5;
    box-sizing: border-box;
    padding: 32px;
    width: 100%;
    border-radius: 8px;
    text-align: center;
    font-size: 20px;
}

.game-container > h1 {
    margin: 8px;
}

.game-container > p {
    width: 360px;
    max-width: 90%;
    margin: 8px auto;
}

.dice-container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 24px;
    width: min-content;
    margin: 32px auto;
}

@keyframes show-dice {
    0% {
        background-color: #F5F5F5;
        visibility: visible;
    }
    90% {
        background-color: #F5F5F5;
        visibility: visible;
    }
    100% { 
        background-color: transparent; 
        visibility: hidden;
    }
}

.countdown {
    font-weight: bold;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transform: scale(1.1, 1.2);
    border-radius: 8px;
    text-align: center;
    animation-name: show-dice;
    animation-duration: 4s;
    animation-fill-mode: forwards;
}

.countdown > * {
    position: absolute;
    color: white;
    height: 56px;
    opacity: 0%;
}

.three, .two, .one {
    animation: countdown-number 1s ease-out;
}

.two {
    animation-delay: 1s;
}

.one {
    animation-delay: 2s;
}

.start {
    animation: countdown-start 1s ease-out 3s;
}

@keyframes countdown-start {
    0% {
        opacity: 0%;
        transform: scale(.9);
    }
    20% {
        opacity: 100%;
        transform: scale(1.05);
    }
    35% {
        opacity: 100%;
        transform: scale(1);
    }
    60% {
        opacity: 100%;
        transform: scale(1);
    }
    70% {
        opacity: 100%;
    }
    100% {
        opacity: 0%;
        transform: scale(1.2, 1.05);
    }
}

@keyframes countdown-number {
    0% {
        opacity: 0%;
        transform: scale(.9);
    }
    10% {
        opacity: 100%;
        transform: scale(1);
    }
    40% {
        opacity: 100%;
        transform: scale(1);
    }
    100% {
        opacity: 0%;
        transform: scale(.9);
    }
}

.die, .roll-button {
    height: 48px;
    font-size: 24px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s ease-out, background-color 0.1s ease-out;
}

.die {
    width: 48px;
    font-weight: bold;
    background-color: #FFF;
}

.roll-button:active {
    transform: scale(.95);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.die:hover {
    transform: scale(1.2);
    box-shadow: 2px 8px 8px rgba(0, 0, 0, 0.10);
}

.roll-stuff {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
}

.roll-button {
    color: #FFF;
    background-color: #5035FF;
    width: 148px;
    min-width: max-content;
    font-weight: 700;
}

.roll-count {
    flex: 1;
    text-align: left;
}

.roll-timer {
    font-family: 'PT Mono', monospace;
    text-align: right;
    flex: 1;
    position: relative;
}

.die.dot-mode {
    display: grid;
    grid-template: repeat(3, 1fr) / 1fr 1fr;
    align-items: center;
    justify-items: center;
    padding: 6px 2px;
}

.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #000;
    display: block;
}

.die.dot-mode[data-die-number="1"] > .dot {
    grid-area: 1 / 1 / 4 / 3;
}

.die.dot-mode[data-die-number="2"] > .dot:first-child {
    grid-area: 1 / 2 / 1 / 2;
}
.die.dot-mode[data-die-number="2"] > .dot:last-child {
    grid-area: 3 / 1 / 3 / 1;
}

.die.dot-mode[data-die-number="3"] > .dot:first-child {
    grid-area: 1 / 2 / 1 / 2;
}

.die.dot-mode[data-die-number="3"] > .dot:nth-child(2) {
    grid-area: 2 / 1 / 2 / 3;
}

.die.dot-mode[data-die-number="3"] > .dot:last-child {
    grid-area: 3 / 1 / 3 / 1;
}

.die.dot-mode[data-die-number="4"] > .dot:nth-child(3),
.die.dot-mode[data-die-number="4"] > .dot:last-child {
    grid-row: 3;
}

.die.dot-mode[data-die-number="5"] > .dot:nth-child(3) {
    grid-column: span 2;
}

.game-history-container {
    box-sizing: border-box;
    padding: 16px 32px;
    margin: auto;
    width: 100%;
    font-size: 20px;
    color: #F5F5F5;
    border-radius: 8px;
    animation: fade-drop-in .5s ease-out;
}

.game-history-container > h2 {
    text-align: center;
}

.game-history-container > table {
    margin: auto;
    width: 100%;
}

.game-history-container > table th {
    text-align: left;
}

th {
    display: none;
}

.game-history-container > table > tbody > tr > td:nth-child(2) {
    font-family: 'PT Mono', monospace;
}

.game-history-container > table > tbody > tr > td:nth-child(3)::after {
   content: ' rolls'; 
}

@keyframes fade-drop-in {
    from {
        transform: translateY(-24px);
        opacity: 0%;
    }
    to { transform: translateY(0px);}
    
}

@media screen and (max-width: 480px) {
    main {
        max-width: 90%;
    }

    .game-container {
        padding: 32px 8px;
    }

    .dice-container {
        grid-template-columns: repeat(5, 1fr) !important;
        gap: 16px;
    }
    .die {
        width: 48px;
        height: 48px;
    }

    .roll-timer, .roll-count {
        font-size: 16px;
    }

    .game-history-container > table > tbody > tr > *:first-child {
        display: none;
    }
}

.record{
    font-size: .9em;
    font-weight: 500;
    font-family: 'Karla', sans-serif;
    position: absolute;
    width: 68px;
    display: block;
    color: #FF8928;
    right: 2px;
    top: -90%;
    text-align: center;
    animation: record .5s ease 0s infinite forwards;
}

@keyframes record {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-5px); }
    100% { transform: translateY(0px); }
}